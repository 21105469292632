
import '../../../Game.css';

function GameCell(props) {
	const { letter, dataState } = props;
	
	return (
		<div className="tile" data-state={dataState}>{letter}</div>
  );
}

export default GameCell;