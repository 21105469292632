import React from "react";
import request from "../data/request";
import noop from "../utilities/noop";
import { config } from "../../config";

export default function useDailyWord(handleError = noop) {
  const [dailyWord, setDailyWord] = React.useState(null);

  const fetchDailyWord = React.useCallback(() => {
    return request(config.DAILY_WORD_ENDPOINT).then(setDailyWord).catch((error) => {
      const newMsg = "Error fetching daily word: " + error.message;  
      console.log(newMsg);
      handleError(new Error(newMsg, { cause: error}));
    })
  }, [handleError, setDailyWord]);

  React.useEffect(() => {
    fetchDailyWord();
  }, [fetchDailyWord]);

  return {
    fetchDailyWord,
    dailyWord
  };
}