import '../../../Game.css';
import GameArea from '../game-area/GameArea'
import { Helmet } from 'react-helmet';
//import React, { useState } from 'react';
import React from 'react';
import useWordList from '../../hooks/use-wordlist';
import useDailyWord from '../../hooks/use-daily-word';
import useWindowDimensions from '../../hooks/use-window-dimensions';
import ToastError from "../toast/toast-error";
import ToastGameComplete from "../toast/toast-game-complete";

function App() {
  const [error, setError] = React.useState(null);
  const [gameResult, setGameResult] = React.useState(null);
  const {wordList} = useWordList(setError);
  const {dailyWord} = useDailyWord(setError);

  const { height } = useWindowDimensions();
  if (dailyWord)  
    console.log(dailyWord.word.wordValue);     
  
  console.log(gameResult);

  if (wordList.length > 0 && dailyWord) {
    return (
      <div className="App">
        <Helmet>
          <title>Joshdle</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <meta name="description" content="Josh's Shameless Wordle Ripoff" />
          <meta name="theme-color" content="#008f68" />
        </Helmet>    
        <GameArea wordList={wordList} correctWord={dailyWord.word.wordValue} height={height} onError={setError} onGameOver={setGameResult}/>
        <ToastError
        message={error && error.message}
        onClose={() => setError(null)}
        isOpen={error !== null}
        severity="error"
      />    
      <ToastGameComplete
        gameResult={gameResult}
        onClose={() => setGameResult(null)}
        isOpen={gameResult !== null}
        setError={setError}
      />          
      </div>
    );
  } else {
    return (
      <div>Loading...</div>  
    );
  }
}

export default App;
