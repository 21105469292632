const dev = {
    "DAILY_WORD_ENDPOINT": "https://jh-desktop:5001/CurrentWord",
    "WORDLIST_ENDPOINT": "https://jh-desktop:5001/WordList"
  }
  
  const prod = {
    "DAILY_WORD_ENDPOINT": "https://wordlish.joshhowe.com/api/CurrentWord",
    "WORDLIST_ENDPOINT": "https://wordlish.joshhowe.com/api/WordList"
  }
  
  //export const config = dev;

  export const config = process.env.NODE_ENV === 'development'
    ? dev
    : prod;