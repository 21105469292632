import React from "react";
import request from "../data/request";
import noop from "../utilities/noop";
import { config } from "../../config";

export default function useWordlist(handleError = noop) {
  const [wordList, setWordlist] = React.useState([]);

  const fetchWordList = React.useCallback(() => {
    return request(config.WORDLIST_ENDPOINT).then(setWordlist).catch((error) => {
      const newMsg = "Error fetching word list list: " + error.message;  
      console.log(newMsg);
      handleError(new Error(newMsg, { cause: error}));
    })
  }, [handleError, setWordlist]);

  React.useEffect(() => {
    fetchWordList();
  }, [fetchWordList]);

  return {
    fetchWordList,
    wordList
  };
}