import '../../../Game.css';
import GameRow from './GameRow.js';
import GameKey from './GameKey.js';

import React, { useState, useEffect } from 'react';

function GameArea(props) {
	//const [wordList, correctWord] = props;
	//console.log(props);
	const wordList = props.wordList;
	const correctWord = props.correctWord;
	const height = props.height;
	
	const correctWordChars = Array.from(correctWord);

	const [currentRow, setCurrentRow] = useState(0);
	const [currentCell, setCurrentCell] = useState(0);
	const [rowLetters, setRowLetters] = useState([[],[],[],[],[],[]]);
	const [dataStates, setDataStates] = useState([
		["empty","empty","empty","empty","empty"],
		["empty","empty","empty","empty","empty"],
		["empty","empty","empty","empty","empty"],
		["empty","empty","empty","empty","empty"],
		["empty","empty","empty","empty","empty"],
		["empty","empty","empty","empty","empty"]
	]);
	const [keyStates, setKeyStates] = useState(new Map());
	
    const onUp = event => {
  	if (event.keyCode === 8) { //Delete Key
			handleDelete();
  	}
  	
  	if (event.keyCode === 13) { //Enter key
			handleEnter();
  	}   		
  		
  	if (event.keyCode >= 65 && event.keyCode <= 90) //letter key
  	{
			handleLetter(event.key);
  	}
  }
  
  	function handleLetter(letter) {
  	//console.log(letter);
  	
		if (currentCell <= 4) {
			let newRowLetters = [...rowLetters]; 
			let newDataStates = [...dataStates]; 
			
			newDataStates[currentRow][currentCell] = "tbd";
			newRowLetters[currentRow][currentCell] = letter;
			
			setDataStates(newDataStates);
			setRowLetters(newRowLetters);  		
			setCurrentCell(currentCell + 1);
		}	  	
  }
  
  	function handleEnter() {	  	
		if (currentRow < 6) {
			const word = rowLetters[currentRow].join("");
			if (currentCell < 5) {
				props.onError(new Error("INCOMPLETE WORD"));
			} else {
				if (wordList.indexOf(word.toLowerCase()) === -1) {
				//if (1 === 2) {	
					props.onError(new Error("Invalid Word"));
				} else {
					let newDataStates = [...dataStates];
					let newKeyStates = new Map(keyStates);
					let foundLetterPositions = [];
					let rowDataStates = ["absent","absent","absent","absent","absent"];
					
					for (var i = 0; i < correctWordChars.length; i++) {
						let correctLetter = correctWordChars[i];

						if (rowLetters[currentRow][i] === correctLetter) {
							rowDataStates[i] = "correct";
							foundLetterPositions.push(i);
							newKeyStates.set(correctLetter, "correct")							
						} else {
							for (var j = 0; j < rowLetters[currentRow].length; j++)	{
								if (rowLetters[currentRow][j] === correctLetter && !foundLetterPositions.includes(j)) {
									rowDataStates[j] = "present";
									
									if (!newKeyStates.has(correctLetter))
										newKeyStates.set(correctLetter,"present");
									
									foundLetterPositions.push(j);
									break;
								}
							}
						}
					}
					
					for (i = 0; i < rowLetters[currentRow].length; i++) {
						const letter = rowLetters[currentRow][i];

						if (!newKeyStates.has(letter) && !foundLetterPositions.includes(i)) {
							newKeyStates.set(letter,"absent");
						}
					}
					

					newDataStates[currentRow] = rowDataStates;
					
					setKeyStates(newKeyStates);
					setDataStates(newDataStates);
					setCurrentRow(currentRow + 1);
					setCurrentCell(0);		
					
					if (word === correctWord) {
						const gameResult={"guesses":currentRow + 1,"rowDataStates":newDataStates};
						props.onGameOver(gameResult);
					}						
				}
			}		
		}  	
  	}
  
  	function handleDelete() {
		if (currentCell > 0) {
			let newDataStates = [...dataStates]; 
			let newRowLetters = [...rowLetters]; 
			
			newDataStates[currentRow][currentCell-1] = "empty";
			newRowLetters[currentRow][currentCell-1] = null;
			
			setDataStates(newDataStates);
			setRowLetters(newRowLetters);
			  		
			setCurrentCell(currentCell - 1);  		
		}  	
  	}

  // Bind and unbind events
  	useEffect(() => {
      	window.addEventListener("keyup", onUp)
      	return () => {
          window.removeEventListener("keyup", onUp)
      	}
 	})
  
  const boardHeight = height - 255;
  const boardWidth = boardHeight - 20;

  return (
	  <div>
	    <header>
	    	<div className="title">JOSHDLE</div>
	    </header>	
	    <div id="game">
			<div id="board-container">
				<div id="board" style={{height:boardHeight, width:boardWidth}}>
					<GameRow letters={rowLetters[0]} dataStates={dataStates[0]} />
					<GameRow letters={rowLetters[1]} dataStates={dataStates[1]} />
					<GameRow letters={rowLetters[2]} dataStates={dataStates[2]} />
					<GameRow letters={rowLetters[3]} dataStates={dataStates[3]} />
					<GameRow letters={rowLetters[4]} dataStates={dataStates[4]} />
					<GameRow letters={rowLetters[5]} dataStates={dataStates[5]} />
					</div>
				</div>
				<div id="keyboard">
					<div class="keyboardrow">
						<GameKey keyValue="q" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="w" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="e" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="r" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="t" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="y" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="u" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="i" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="o" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="p" keyStates={keyStates} handleLetter={handleLetter}/>
					</div>
					<div class="keyboardrow">
						<GameKey keyValue="a" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="s" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="d" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="f" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="g" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="h" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="j" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="k" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="l" keyStates={keyStates} handleLetter={handleLetter}/>
					</div>			
					<div class="keyboardrow">
						<button data-key="" onClick={handleEnter}>enter</button>
						<GameKey keyValue="z" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="x" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="c" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="v" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="b" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="n" keyStates={keyStates} handleLetter={handleLetter}/>
						<GameKey keyValue="m" keyStates={keyStates} handleLetter={handleLetter}/>
						<button data-key="" onClick={handleDelete}>back</button>
					</div>					
				</div>
				<div class="toaster" id="game-toaster" />
				<div class="toaster" id="system-toaster" />
			</div>
		</div>
  );
}

export default GameArea;