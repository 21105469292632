import '../../../Game.css';

function GameKey(props) {
    let dataState = "none";
    
    if (props.keyStates && props.keyStates.has(props.keyValue)){    
        dataState = props.keyStates.get(props.keyValue);
    }

    return (
		<button data-key={props.keyValue} data-state={dataState} onClick={() => {props.handleLetter(props.keyValue)}}>{props.keyValue}</button>
    );
}

export default GameKey;