
import '../../../Game.css';
import GameCell from './GameCell.js';

function GameRow(props) {
	const { letters, dataStates } = props;
	
	//console.log(letters);
	
  return (
		<div className="gamerow">
			<GameCell letter={letters[0]} dataState={dataStates[0]} />
			<GameCell letter={letters[1]} dataState={dataStates[1]} />
			<GameCell letter={letters[2]} dataState={dataStates[2]} />
			<GameCell letter={letters[3]} dataState={dataStates[3]} />
			<GameCell letter={letters[4]} dataState={dataStates[4]} />
	  </div>
  );
}

export default GameRow;